import { Component, NgZone } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { DataService } from "./services/data.service";
import { DataHandlerService } from "./services/data-handler.service";
import { AuthService } from "./services/auth.service";
import { FirebaseTokenService } from "./services/firebase-token.service";
import { firebaseConsts } from "./constants";
import { version } from '../../package.json';

@Component({
  selector: "body",
  template: "<router-outlet></router-outlet>",
  // *ngIf='token'
})
export class AppComponent {
  title = "Juma Cliente";
  public version: string = version;
  constructor(
    private router: Router,
    private data: DataService,
    private dataHandlerService: DataHandlerService,
    private authService: AuthService,
    private ngZone: NgZone
  ) {
    // const menuItems = navItems;
    // data.setNavItems(menuItems);
    this.ngZone.run(() => {
      this.checkUser();
    });
    data.app = this;
  }

  setUser(id) {
    console.log('Setting user type...');
    this.data.setCurrentUserId(id);
    return this.dataHandlerService.updateClientData(id);
  }

  async checkTypeUser(uid: string) {
    const user = await this.dataHandlerService.db.getDoc(firebaseConsts.collections.clients, uid);
    if (user) {
      console.log('User type client...');
      return user;
    } else {
      console.log('User type manager, admin...');
      this.data.userType = await this.dataHandlerService.db.getDoc(firebaseConsts.collections.users, uid);
      await this.dataHandlerService.prepareToAdminManager(this.data.userType);
      return this.data.client ? this.data.client : this.data.clients[0];
    }
  }

  async checkUser() {
    this.authService.getAuth().onAuthStateChanged(async (user: any) => {
      if (user) {
        this.data.fbToken = new FirebaseTokenService();
        this.data.currentUser = user;
        const client = await this.checkTypeUser(user.uid);
        // const id = 'KUzL9rZPNYOKXqTXNaSKwS3Nb9H3'; //prime
        // const id = 'ASWSxznRCKaaQHvHSep2TkivVe93'; //padim repres
        // const id = '8rfloDaww6cQpmw7Ox0fn7UHVum2'; //Hunter repres
        // const id = 'fis4BBsDueQy3yncujMY9mayqb93'; //Spoleto
        // console.log(this.data.currentUser.uid);
        const id = client?.id;
        await this.setUser(id);
        await this.data.fbToken.getToken(this.data.currentUser);
        this.data.oauthService = this.authService.getAuth();
      } else {
        this.ngZone.run(() => {
          this.router.navigate(["login"]);
        });
      }
    });
  }

  public logout() {
    this.data.oauthService.logOut();
  }

  async ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  
}
