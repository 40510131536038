import { Balance } from './../models/interfaces/balance';
import { DashboardComponent } from './../views/dashboard/dashboard.component';
import { Injectable } from '@angular/core';
import { Client, Settings } from '../models/client';
import { Subscription } from 'rxjs';
import { Call, Subsidiary } from '../models/call';
import { Destination } from '../models/destination';
import { NeighborhoodTariff } from '../models/neighborhood-tariff';
import { IfoodStoreStatus } from '../models/enuns/iFood';
import { New } from '../models/news';
import { icon, latLng, LatLng, marker, Marker } from 'leaflet';
import { FirebaseTokenService } from './firebase-token.service';
import * as L from 'leaflet';
import { CallStartOnList } from '../models/enuns/callStartOn';
import { CallFinishableByList } from '../models/enuns/callFinishable';
// import { navItems } from '../_nav';
import { INavData } from '@coreui/angular';
import { DynamicTariff } from '../models/interfaces/tariff';
import AnotaAiCheck from '../models/enuns/anotaAiCheck';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  // inputMode: CurrencyMaskInputMode.FINANCIAL
};

@Injectable({
  providedIn: 'root'
})
export class DataService {
  host: any;
  userType: any;
  app: any;
  apiToken: string;

  keyToUse = '';
  keys = [];

  getKey() {
    if (!this.keys.length) return null;
    const keyIndex = Math.floor(Math.random() * this.keys.length);
    return this.keys[keyIndex];
 }

  private navItems: INavData[];

  setNavItems(items: INavData[]) {
    this.navItems = items;
  }

  isManagerOrAdmin() {
    return this.userType?.claims?.admin || this.userType?.claims?.manager;
  }

  getNavItems() {
    return this.navItems;
  }

  clients: any = [];
  tryTimes = 0;

  map: L.Map;
  fbToken: FirebaseTokenService;

  icon = icon({
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    iconUrl: "assets/img/avatars/pinacepted.png",
  });

  iconbusy = icon({
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    iconUrl: "assets/img/avatars/pinongoing.png",
  });

  iconfree = icon({
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    iconUrl: "assets/img/avatars/pinafree.png",
  });

  flagFin: Marker;
  flagFinList: Marker[] = [];

  news: New[]
  dashboard: DashboardComponent;

  driversAvailables = [];
  autoCompleteStreetName: any;

  deliveries: Call[] = [];
  deliveriesbyDriver: any[] = [];

  centerBound = {
    lat: 0,
    lng: 0,
  };

  driverList: any = [];
  nearOnlineDrivers: any = [];
  driver: any;

  distanceKmStrictBounds = 50;//50KM
  subsidiary: Subsidiary;

  weekTotal = [];
  columns = 0;
  paymentTypes = [];
  paymentTypesIfoodList = [];
  categoryTypes = [];
  anotaAiStatus = [{ key: null, value: 'Manualmente'}, { key: AnotaAiCheck.Pronto, value: 'Quando o status estiver "Pronto"'}];
  transactTypes = [];

  defaultPaymentType: number; //categoryDriver
  defaultCategoryType: number;
  defaultCategoryTypeName: any;
  marker: Marker<any>;
  layers = [];
  lastMovedMarker: Date;

  getBounds(centerBound, radiusKms: number) {
    let radiusLatLng = radiusKms / 100;
    const bounds = {
      north: centerBound.lat + radiusLatLng,
      south: centerBound.lat - radiusLatLng,
      east: centerBound.lng + radiusLatLng,
      west: centerBound.lng - radiusLatLng,
    };
    return bounds;
  }

  callStartOnInfoConfig = '';
  callHasBackFinishableByInfoConfig = '';

  setInfoConfigOptions() {
    let settings: Settings  = this.client.settings;
    let callStart = CallStartOnList.find(row => row.key == settings?.callStartOn);
    if (!callStart) {
      callStart = CallStartOnList[0];
      this.client.settings.callStartOn = callStart.key;
    }
    this.callStartOnInfoConfig = callStart.text;

    let callHasBackFinishableBy = CallFinishableByList.find(row => row.key == settings?.callHasBackFinishableBy);
    if (!callHasBackFinishableBy) {
      callHasBackFinishableBy = CallFinishableByList[0];
      this.client.settings.callHasBackFinishableBy = callHasBackFinishableBy.key;
    };
    this.callHasBackFinishableByInfoConfig = callHasBackFinishableBy.text;
  }

  googlePlacesOptions = {
    bounds: this.getBounds(this.centerBound, this.distanceKmStrictBounds),
    componentRestrictions: { country: "BR" },
    strictBounds: true
    // types: ["establishment", "geocode"],
    // fields: ['address_components']
  };

  googlePlacesOptionsNeighborhood = {
    types: [],
    fields: ["address_components", "name"],
    componentRestrictions: { country: "BR" },
  };

  isPrinting = false;

  images = [];
  homeImage = '';

  tariff: NeighborhoodTariff;
  currentTariffs = [];

  cost = 0;
  distanceInfo = "";
  durationInfo = "";

  showForm = {
    manager: "manager",
    edit: "edit",
    new: "new",
    newOnlyValueDriver: "newOnlyValueDriver",
    search: "search",
  };

  sub

  registerPending = false;
  destinationEdit: Destination
  currentForm: any = this.showForm.manager;
  selectedCall: Call;
  hasNewMessage = false;
  oauthService: any;
  loading = true;
  warning: string = "";
  info: string = "";
  costlist = [];

  textCompleteRegister = "Registro incompleto. Antes de iniciar, cadastre as informações de endereço, tipos de pagamentos. No menu lateral em CADASTROS -> Empresa"

  franchiseId = '1';
  balance: Balance;

  //<dashboard>
  subscribeWatchCallPendingList: Subscription;
  subscribeWatchCallWaitingList: Subscription;
  subscribeWatchCallCompleteList: Subscription;
  subscribeWatchCurrentDeliveries: Subscription;
  subscribeWatchTariffs: Subscription;
  subscribeWatchTariffsDynamics: Subscription;
  subscribeWatchTariffsDynamicsScheduled: Subscription;
  subscribeDriversAvailable: Subscription;
  subscribeWatchBalance: Subscription;
  subscriptionCheckIfood: Subscription;
  subscriptionCheckToken: Subscription;
  subscriptionCheckLock: Subscription;
  subscribeActiveDrivers: Subscription;

  subscriptionCheckDynamicTariff: Subscription;

  lastCheckLock: Date;

  waintinglist = [];
  activelist = [];
  activelistScoket = [];
  client: Client = new Client();
  clientSelected: any;
  clientSelect2 = [];

  subsidiarySelected = null;
  subsidiaries: any = [];

  currentUser: firebase.User | null;
  private _currentUserId: string;
  test: any;
  token: any;
  iFoodStoreOnline: IfoodStoreStatus;
  partnerTimeAutoCall = 0;
  ifoodActivated: boolean;
  anotaaiActivated: boolean;
  lastUpdateDriverList: any;
  lockHoursRemains: number;

  dynamicTariff: DynamicTariff;
  dynamicTariffScheduled: DynamicTariff;

  //</dashboard>

  latitudeClient: number = 0;
  longitudeClient: number = 0;
  currentLocation: LatLng;

  latitudeSubsidiary: number = 0;
  longitudeSubsidiary: number = 0;

  allowSetLocationDraggable = false;

  constructor() {
    this.client = new Client();
  }

  setCurrentUserId(uid: string) {
    this._currentUserId = uid;
  }

  currentUserId() {
    if (this._currentUserId && this._currentUserId.length > 0)
      return this._currentUserId
    else {
      this._currentUserId = this.currentUser.uid;
      return this._currentUserId;
    }
  }

  getUserToken(forceRefresh) {
    return this.currentUser.getIdToken(forceRefresh);
  }

  clearTotalCallInfo() {
    this.cost = 0;
    this.distanceInfo = "";
    this.durationInfo = "";
  }

  updateLocation() {
    if (this.client.address) {
      this.latitudeClient = this.client.address.location.latitude;
      this.longitudeClient = this.client.address.location.longitude;
    }
  }

  private removeMap(): void {
    if (this.map) {
      this.map.remove();
    }

    this.map = L.map("map", {
      center: [0, 0],
      zoom: 13.5,
    });

    // this.initMap(null);
  }


  initMap(coordinate: LatLng = null) {
    let coords = coordinate;
    if (!coordinate) {
      coords = latLng(this.latitudeClient, this.longitudeClient)
    };
    this.removeMap();
    this.map.panTo(new L.LatLng(coords.lat, coords.lng));
    // const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const ight_nolabelsUrl = 'https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png'
    const light_only_labelsUrl = 'https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png';
    const jawgUrl =  "https://tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=fpwnLtioABCYXTZyj8JetjvNjEAmiTId2pbE0HtZLLT8dipFqYCGxgNHRcA2ylU7";
    const light_all = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'

    L.tileLayer(ight_nolabelsUrl, {
      maxZoom: 18,
      minZoom: 12,
      // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    }).addTo(this.map);

    L.tileLayer(light_only_labelsUrl, {
      maxZoom: 18,
      minZoom: 12,
      // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      // pane: 'shadowPane'
    }).addTo(this.map);

    L.marker([coords.lat, coords.lng], {
      icon: icon({
        iconSize: [30, 30],
        iconAnchor: [13, 20],
        iconUrl: 'assets/img/brand/market.png'
      })
    }).addTo(this.map);

    // const color = "grey";

    // L.circle(coords, 1000, { color: color, opacity: .0001 }).addTo(this.map);

  }
}
