import Dictionary from "./dictionary";


export enum RestMethod {
    POST = 'POST', 
    PUT = 'PUT'
}

export interface WebhookParams {
    url: string;
    headers: any,
    params: any,
    method: RestMethod
}

export interface IntegrationApi {
    activated: boolean;
    registeredAt: any | null;
    registered: boolean;
    disabledAt: any | null;
    system: string | null;
    secret: string | null;
    defaultPayment: number | null;
    webhookLocationParams: WebhookParams | null,
    webhookStatusParams: WebhookParams | null,
    allowCreateCallByAnother: boolean | null
}