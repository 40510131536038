import AnotaAiCheck from "../enuns/anotaAiCheck";

export interface PartnerIntegration {
    name: string;
    createdAt: any;
    disabledAt: any;
    merchantId: string;
    token: string;
    partnerTimeAutoCall: number;
    paymentType: number | null;
    disabledByAdmin: boolean;
  }

  export class Ifood implements PartnerIntegration {
    createdAt: any;
    disabledAt: any;
    merchantId: string;
    token: string | null = null;
    paymentType: number | null;
    confirmDelivery: any;
    disabledByAdmin: boolean;
    partnerTimeAutoCall: number;
    name: string;
    constructor() {
      this.createdAt = null;
      this.disabledAt = null;
      this.merchantId = '';
      this.partnerTimeAutoCall = 0;
      this.paymentType = 0;
    }
    
  }

  export class AnotaAi implements PartnerIntegration {
    name: string;
    createdAt: any;
    disabledAt: any;
    merchantId: string;
    token: string | null;
    externalToken: string | null; 
    defaultCategory: number | null;
    paymentType: number | null;
    disabledByAdmin: boolean;
    partnerTimeAutoCall: number;
    callDriverOnStatus: AnotaAiCheck | null
    constructor() {
      this.createdAt = null;
      this.disabledAt = null;
      this.merchantId = '';
      this.partnerTimeAutoCall = 0;
      this.paymentType = 0;
    }
  }