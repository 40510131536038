<div class="app-body">
    <!--  [ngStyle]="{background: 'url(assets/img/brand/logobanner.png)'}" -->
    <main class="main d-flex align-items-center">
      <div class="container">
  
        <div class="row">
          <div class="col-md-10 mx-auto">
  
            <!-- <div class="card-group">
              <div class="card p-4">
  
                <div class="card-body">
  
                  <div class="text-center">
                    <img src="assets/img/brand/logo.png" width="28%" height="18%" alt="Responsive image">
  
                  </div>
                </div>
              </div>
  
            </div> -->
  
          </div>
        </div>
  
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="card-group">
              <div class="card p-4">
  
                <div class="card-body">
                  <!-- <alert type="warning">
                    <strong>Warning!</strong> Better check yourself, you're not looking too good.
                  </alert> -->
  
                  <div class="loading">
                    <ngx-loading [show]="loading"
                      [config]="{animationType: ngxLoadingAnimationTypes.circleSwish, backdropBorderRadius: '3px'}">
                    </ngx-loading>
                  </div>
  
  
                  <form>
                    <h1>Login</h1>
                    <p class="text-muted">Acesse com sua conta</p>

                    <alert *ngIf="info.length > 0" type="success">
                      <strong>Informação!</strong> {{info}}.
                    </alert>
  
                    <alert *ngIf="warn.length > 0" type="warning">
                      <strong>Atenção</strong> {{warn}}.
                    </alert>
  
                    <alert *ngIf="error.length > 0" type="danger">
                      <strong>Erro!</strong> {{error}}.
                    </alert>
  
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input #inputemail type="email" class="form-control" placeholder="Email" name="email" autocomplete="username"
                        required [(ngModel)]="email">
                    </div>
                    <div class="input-group mb-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                        <!-- <div (capsLock)="capsOn=$event"> -->
                          <input type="password" class="form-control" placeholder="Senha" name="password"
                          autocomplete="current-password" required [(ngModel)]="password" (keydown.Enter)="doLogin()">  
                          <!-- <label *ngIf="capsOn">Caps Locked</label> -->
                        <!-- </div> -->
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <button type="button" class="btn btn-dark px-4" (click)="doLogin()">Login</button>
                      </div>
                      <div class="col-6 text-right">
                        <button type="button" class="btn btn-link px-0" (click)="resetPassword()">Esqueceu a
                          senha?</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- <div class="card text-white bg-dark py-5 d-md-down-none" style="width:44%">
                <div class="card-body text-center">
                  <div>
                    <h2>Precisa de entregador?</h2>
                    <p>Registre-se agora mesmo e chame quantos precisar logo em seguida.</p>
                    <button type="button" class="btn btn-light active mt-3" (click)="callRegister()">Registrar
                      Agora!</button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <footer class="page-footer font-small blue pt-4">
    <div class="footer-copyright text-center py-1">
        <img src="assets/img/brand/juma-topo.png" width="10%" height="18%" alt="Responsive image">

      </div>
    <div class="footer-copyright text-center py-3">Copyright ©{{year}} |
        <a href="https://www.boaztech.com.br/"> Boaztech</a>
      </div>
  </footer>