import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { DataService } from '../data.service';
import { CostResponse } from 'local-packages/ngx-autocom-place/src/lib/model/costResponse';
import CategoryDriver from 'src/app/models/enuns/categoryDriver';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private situacaoCadastrada: string = '1';

  constructor(
    private http: HttpClient,
    private data: DataService
  ) { }

  public getAny(url) {
    return this.http.get(url).toPromise();
  }

  async sendPush(body) {
    const cloudfunction = environment.firebase.functons;
    const token = await this.data.currentUser.getIdToken();
    let headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json');
    const url = `${cloudfunction}/notifications`;
    return this.http.post(url, JSON.stringify(body), { headers: headers }).toPromise();
  }

  async getNewDestination(body) {
    const cloudfunction = environment.firebase.functons;
    const token = await this.data.fbToken.getToken(this.data.currentUser);
    let headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Origin', '*')
    const url = `${cloudfunction}/destinations`;
    return this.http.post(url, body, { headers: headers }).toPromise();
  }

  async post(body, endpoint) {
    const cloudfunction = environment.firebase.functons;
    const token = await this.data.fbToken.getToken(this.data.currentUser);
    let headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'application/json')
      .append('Access-Control-Allow-Origin', '*');
    const url = `${cloudfunction}/${endpoint}`;
    return this.http.post(url, JSON.stringify(body), { headers: headers }).toPromise();
  }

  async get(endpoint) {
    const cloudfunction = environment.firebase.functons;
    const token = await this.data.currentUser.getIdToken();
    let headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    const url = `${cloudfunction}/${endpoint}`;
    return this.http.get(url, { headers: headers }).toPromise();
  }

  async getCost(distance: number, category: CategoryDriver) {
    const cloudfunction = environment.firebase.functons;
    const token = await this.data.currentUser.getIdToken();
    const queryParameters = new HttpParams({
      fromObject: {
        'distance': distance.toString(),
        'category': category.toString()
      }
    });
    let headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    const url = `${cloudfunction}/cost`;

    return this.http.get<CostResponse>(url, { params: queryParameters, headers: headers }).toPromise();
  }

  async getCurrentIp() {
    return this.http.get<any>('https://geolocation-db.com/json/').toPromise();
  }

}