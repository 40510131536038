import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import * as firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(public afAuth: AngularFireAuth, public router: Router) {}

  public loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  public logoutUser(redirectToLogin: boolean) {
    this.afAuth.auth.signOut().then(() => {
      if (redirectToLogin) this.router.navigate(["/login"]);
    });
  }

  public getAuth() {
    return this.afAuth.auth;
  }

  public currentUser() {
    return this.afAuth.auth.currentUser;
  }

  public getLoginToken() {
    return this.afAuth.auth.currentUser.getIdToken();
  }

  public resetPassword(email: string): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
