import { Address } from "./address";
import { CallFinishableBy } from "./enuns/callFinishable";
import { CallStartOn } from "./enuns/callStartOn";
import { IntegrationApi } from "./interfaces/integration-api";
import { AnotaAi, Ifood } from "./interfaces/partnerIntegration";

export class Rating {
  avg: number;
  quantity: number;
  constructor() {
    this.avg = 0;
    this.quantity = 0;
  }
}

export class Settings {
  callStartOn: CallStartOn;
  callHasBackFinishableBy: CallFinishableBy;
  constructor() {
    this.callStartOn = CallStartOn.Undefined,
    this.callHasBackFinishableBy = CallFinishableBy.Driver
  }
}

export interface Partner {
  name: string;
  doc: string;
  email: string;
}

export class Client {
  id?: string;
  subsidiary: string;
  businessName: string;
  name: string;
  registryNumber: string;
  contractType: string;
  address: Address;
  categories: any[];
  paymentTypes: any[];
  observations: string;
  observationsToDriver: string;
  rating: Rating;
  phoneNumber: string;
  ifood: Ifood;
  anotaai: AnotaAi;
  settings: Settings;
  integration: IntegrationApi | null;
  partners: Partner[] | null;
  constructor() {
    this.businessName = "";
    this.ifood = new Ifood();
    this.anotaai = new AnotaAi();
    this.settings = new Settings();
  }

  static prepareData(client: Client) {
    const address = {
      zipCode: client.address.zipCode, 
      street: client.address.street,
      neighborhood: client.address.neighborhood,
      number: client.address.number,
      complement: client.address.complement ? client.address.complement : null,
      city: client.address.city,
      state: client.address.state,
      ibge: client.address.ibge ? client.address.ibge : "0",
      location: client.address.location,
      locationUpdatedAt: client.address.locationUpdatedAt ?? null
    };

    const ifood = client.ifood?.merchantId?.length ? Object.assign({}, client.ifood) : null;
    
    if (ifood) {
      ifood.paymentType = Number(ifood.paymentType);
      ifood.confirmDelivery = (ifood.confirmDelivery == "true" || ifood.confirmDelivery == true) ? true : false
    }

    const anotaai = client.anotaai?.merchantId?.length ? Object.assign({}, client.anotaai) : null;
    if (anotaai) {
      anotaai.paymentType = Number(anotaai.paymentType);
    }

    let jsonData = {
      address: address,
      rating:
        client.rating == undefined ? { avg: 0, quantity: 0 } : client.rating,
      observationsToDriver: client.observationsToDriver
        ? client.observationsToDriver
        : null,
      observations: client.observations ? client.observations : null,
      categories: client.categories,
      paymentTypes: client.paymentTypes,
      ifood: ifood,
      anotaai: anotaai,
      settings: client.settings ? Object.assign({}, client.settings) : null,
      integration: client.integration ? Object.assign({}, client.integration) : null,
      partners: client.partners ? client.partners : null
    };
    return jsonData;
  }
}
