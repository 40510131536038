import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    title: true,
    name: "Principal",
    icon: "icon-puzzle",
  },
  {
    name: "Painel",
    url: "/dashboard",
    icon: "icon-speedometer",
  },
  {
    title: true,
    name: "Cadastros",
    icon: "icon-puzzle",
  },
  {
    name: "Empresa",
    url: "/company",
    icon: "icon-home",
  },
  {
    title: true,
    name: "Relatorios",
  },
  {
    name: "Entregas",
    url: "/reports/deliveries",
    icon: "icon-list",
  },
  {
    title: true,
    name: "Pré-Pago",
  },
  {
    name: "Movimentações",
    url: "/balance/list",
    icon: "icon-list",
  },
  {
    name: "Crédito",
    url: "/balance/add",
    icon: "icon-plus",
  },
  {
    title: true,
    name: "Outros ",
  },
  // {
  //   name: "Novidades",
  //   url: "/news",
  //   icon: "icon-news",
  //   badge: {
  //     variant: 'danger',
  //     text: ''
  //   }
  // },
  // {
  //     name: 'Tarifa por Bairro',
  //     url: '/registrations/neighborhoodtariffs',
  //     icon: 'icon-calculator'
  // }
  // {
  //     title: true,
  //     name: 'Configurações',
  //     icon: 'icon-puzzle'
  // },
  // {
  //     name: 'Gerais',
  //     url: '/settings',
  //     icon: 'icon-calculator'
  // }
];
