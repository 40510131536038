import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { parseISO } from "date-fns";
import { Observable } from "rxjs";
import { firebaseConsts } from "../constants";
import { StatusCall } from "../models/call";
import {
  BalanceStatus,
  BalanceType,
  TransactStatus,
} from "../models/enuns/balanceType";
import { Balance } from "../models/interfaces/balance";
import { ApiService } from "./api/api.service";
import { FirebaseDatabaseService } from "./firebase-database.service";
import * as firebase from "firebase/app";
import { MoneyService } from "./money.service";
import { DataService } from "./data.service";
import PaymentType from "../models/enuns/paymentType";

@Injectable({
  providedIn: "root",
})
export class BalanceService {
  constructor(
    private db: FirebaseDatabaseService,
    public afs: AngularFirestore,
    private api: ApiService,
    public moneyService: MoneyService,
    private dataService: DataService
  ) {}

  getInTransactions(ownerId: string, dateIni, dateFin) {
    let ref = this.afs.collection(firebaseConsts.collections.balance).ref;
    let query: Query;
    query = ref
      .where("owner.id", "==", ownerId)
      .where("type", "==", BalanceType.Addition)
      .orderBy("createdAt")
      .startAt(dateIni)
      .endAt(dateFin);
    return this.db.getCollection(query);
  }

  updateReceiptUrl(docId: string, url: string) {
    const doc = {
      receiptUrl: url,
    };
    return this.db.updateDoc(firebaseConsts.collections.balance, doc, docId);
  }

  getOutTransactions(ownerId: string, dateIni, dateFin) {
    let ref = this.afs.collection(firebaseConsts.collections.calls).ref;
    let query: Query;
    query = ref
      .where("origin.clientId", "==", ownerId)
      .where("status", "==", StatusCall.complete)
      .where("payment.type", "==", PaymentType.Prepago)
      // .where("prepaid.transactStatus", "==", TransactStatus.Done)
      .orderBy("createdAt")
      .startAt(dateIni)
      .endAt(dateFin);
    return this.db.getCollection(query);
  }

  sendNew(transact: Balance) {
    return this.api.post(transact, "balance/new");
  }

  async doTransact(ownerId: string, value: number) {
    const result = {
      success: false,
      message: "",
      balance: null as any,
      balanceId: null as any,
    };
    const currentBalanceRef = this.afs
      .collection(firebaseConsts.collections.balance)
      .ref.where("owner.id", "==", ownerId)
      .where("status", "==", BalanceStatus.Approved)
      .where("type", "==", BalanceType.Addition)
      .orderBy("approvedDate", "desc")
      .limit(1);
    const currentBalanceSnapshot = await currentBalanceRef.get();
    if (currentBalanceSnapshot.empty) {
      result.success = false;
      result.message = "Balance doc not found";
    } else {
      const docBalanceRef = currentBalanceSnapshot.docs[0].ref;
      await this.afs.firestore.app.firestore().runTransaction((transaction) => {
        return transaction.get(docBalanceRef).then(async (snapshot) => {
          const currentBalance = snapshot.get("currentBalance");
          result.balanceId = snapshot.id;
          if (currentBalance >= value) {
            const newBalance = {
              currentBalance: currentBalance - value,
            };
            transaction.update(docBalanceRef, newBalance);
            result.success = true;
            result.balance = newBalance;
          } else {
            result.success = false;
            result.message = "Saldo insuficiente";
          }
        });
      });
    }
    return result;
  }

  async doTransactCall(ownerId: string, total: number, type: BalanceType, callId: string) {
    const totalCents = this.moneyService.convertToCents(total);
    let result: any = { success: false };
    const callRef = this.afs.collection(firebaseConsts.collections.calls).doc(callId);
    const balanceTranRef = this.afs.collection('balance-transactions');
    if (type == BalanceType.Subtraction) {
      result = await this.doTransact(ownerId, totalCents);
      if (result.success) {
        const prepaid = {
          balanceId: result.balanceId,
          transactStatus: TransactStatus.Done,
          date: parseISO(new Date().toISOString())
        };
        callRef.update({ prepaid: prepaid, updatedAt: parseISO(new Date().toISOString()) });
        balanceTranRef.add({
          balanceId: result.balanceId,
          callId: callId, value: totalCents,
          type: BalanceType.Subtraction,
          createdAt: parseISO(new Date().toISOString())
        })
      }
    }
    return result;
  }

  async hasBalance(total: number) {
    const response = {msg: 'Saldo do pré-pago insuficiente', hasBalance: false};
    const id = this.dataService.client.id;
    const resp: any = await this.get(id);
    if (resp && resp.balance && resp.balance.currentBalance > 0) {
      const totalCents = this.moneyService.convertToCents(total);
      const hasBalance = totalCents <= resp.balance.currentBalance;
      response.hasBalance = hasBalance;
      if (hasBalance) {
        response.msg = "Saldo do pré-pago ";
      }
      return response;
    } else {
      return response;
    }
  }

  async get(ownerId: string) {
    const result = { success: false, message: '', balance: null as any };
    const priorBalanceRef = this.afs.collection(firebaseConsts.collections.balance).ref
      .where('owner.id', '==', ownerId)
      .where('status', '==', BalanceStatus.Approved)
      .where('type', '==', BalanceType.Addition)
      .orderBy('approvedDate', 'desc')
      .limit(1);
    const currentBalanceSnapshot = await priorBalanceRef.get();

    if (currentBalanceSnapshot.empty) {
      const balance = {
        currentBalance: 0,
        updatedAt: null
      };
      result.success = true;
      result.balance = balance;
      return result;
    } else {
      const docRef = currentBalanceSnapshot.docs[0].ref;
      await docRef.get().then(async (snapshot) => {
        const currentBalance = snapshot.get('currentBalance');
        const updatedAt = snapshot.get('updatedAt');
        const balance = {
          ...snapshot.data(),
          id: snapshot.id,
          currentBalance: currentBalance,
          updatedAt: new Date(updatedAt.seconds * 1000)
        };
        result.success = true;
        result.balance = balance;
        // return result;
      });
    }
    return result;
  }
}
