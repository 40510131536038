export enum CallBy  {
    Radius = 'radius',
    All = 'all',
    RadiusAndAll = 'radiusAndAll'
}

export enum CalcRoute  {
    Splitted = 'splitted',
    Fulled = 'fulled'
}

export enum AutoComplete  {
    Google = 'google',
    Boaztech = 'boaztech',
    Aws = 'aws'
}