enum AnotaAiCheck {
    EmAnalise = 0,
    EmProducao = 1,
    Pronto = 2,
    Finalizado = 3,
    Cancelado = 4,
    Negado = 5,
    SolicitacaoCancelamento = 6
}

export default AnotaAiCheck;