export const environment = {
    production: false,
    name: 'Development',
    firebase: {
        apiKey: "AIzaSyChSnFyt8rQRcklZFi1282l5M3FVq9bFhY",
        authDomain: "juma-entregas-qa.firebaseapp.com",
        projectId: "juma-entregas-qa",
        storageBucket: "juma-entregas-qa.appspot.com",
        messagingSenderId: "701294701810",
        appId: "1:701294701810:web:616d0c145161bf628f346b",
        databaseURL: "https://juma-entregas-qa.firebaseio.com",
        functons: "https://southamerica-east1-juma-entregas-qa.cloudfunctions.net/api"
    },
    socketUrl: 'https://boaztech-delivery-tracker.herokuapp.com'
};