import { Component } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { AuthService } from "src/app/services/auth.service";
import { Select2OptionData } from "ng-select2";
import { DataHandlerService } from "src/app/services/data-handler.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  select2Options: any;
  select2OptionsSub: any;
  public sidebarMinimized = false;
  
  year = new Date(Date.now()).getFullYear();
  name: string;
  email: string;
  phoneNumber: string;

  warning = '';
  info = '';

  constructor(
    public data: DataService, 
    private authService: AuthService,
    private dataHandlerService: DataHandlerService
    ) {}

  ngOnInit(): void {
    if (!this.select2Options) {
      this.select2Options = {
        width: "320",
        templateResult: this.templateResult,
        templateSelection: this.templateSelection,
      };

      this.select2OptionsSub = {
        width: "180",
        templateResult: this.templateResult,
        templateSelection: this.templateSelection,
      };
    }
  }

  async onSubChanged(subId) {
    this.dataHandlerService.setCurrentClient(subId);
  }

  async onClientChanged(id) {
    if (!id) return;
    const client = this.data.clients.find(row => {
      return row.key == id; 
    })
    this.data.client = client;
    this.data.setCurrentUserId(id);
    await this.dataHandlerService.updateClientData(id);
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  sendPassword() {
    this.authService
      .resetPassword(this.data.currentUser.email)
      .then(async (_) => {
        this.info = "Link para alteração senha enviado para endereço: " + this.data.currentUser.email;
        this.warning = '';
        // await this.clearInfo();
      })
      .catch(err => {
        if (err && err.code) {

          switch (err.code) {
            case "auth/too-many-requests":
              this.info = '';
              this.warning = "Bloqueamos todas as solicitações de mudança de senha deste dispositivo devido a atividade incomum. Tente mais tarde.";
              break;
          }
        }
      })
      ;
  }

  clearInfo() {
    // this.sleep(5000);
    setTimeout(function (this) {
      this.data.info = "";
      console.log('done');
    }, 5000);
    // this.data.info = "";
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }

  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }

    return jQuery(
      '<div class="row"> <div class="col-sm-10 col-lg-10"> ' +
      '<span class="driverSelect"><b> ' +
      `${state.text}` +
      "</b></span> </div> </div>"
    );
  };

  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }

    return jQuery("<span>" + state.text + "</span>");
  };
}
