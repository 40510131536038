import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import * as geofirex from "geofirex";
import { ApiClientService } from "./api-client.service";
import { environment } from "src/environments/environment";
import { Destination } from "../models/destination";
import { DataService } from "./data.service";
import { MapsAPILoader } from "@agm/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { googleParams } from "../constants";
// import { Http } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class GeolocationService {
  API_URL: string;

  public origin: any;
  public destination: any;

  geo = geofirex.init(firebase);
  distanceMatrixService: google.maps.DistanceMatrixService;
  diretctionsService: google.maps.DirectionsService;
  constructor(
    private api: ApiClientService,
    public data: DataService,
    public mapsAPILoader: MapsAPILoader,
    public http: HttpClient
  ) {
    mapsAPILoader.load().then(() => {
      this.distanceMatrixService = new google.maps.DistanceMatrixService();
      this.diretctionsService = new google.maps.DirectionsService();
    });

    const API_KEY = googleParams.geocodeApikey;
    this.API_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${API_KEY}&address=`;
  }

  getPoint(lat, lon) {
    const point = this.geo.point(lat, lon);
    return point.geopoint;
  }

  async getHaversineDistanceAsync(
    latOrigin,
    lonOrigin,
    latDestination,
    lonDestination
  ) {
    const from = this.geo.point(latOrigin, lonOrigin);
    const to = this.geo.point(latDestination, lonDestination);
    return await this.geo.distance(from, to);
  }

  getHaversineDistance(
    latOrigin,
    lonOrigin,
    latDestination,
    lonDestination
  ) {
    const from = this.geo.point(latOrigin, lonOrigin);
    const to = this.geo.point(latDestination, lonDestination);
    return this.geo.distance(from, to);
  }

  async getHaversineBearing(
    latOrigin,
    lonOrigin,
    latDestination,
    lonDestination
  ) {
    const point = this.geo.point(latOrigin, lonOrigin);
    return await this.geo.bearing(latDestination, lonDestination);
  }

  getTravelInfoDirections(
    destination: Destination,
    coordsOrigin,
    coordsDestination
  ) {
    console.log("getTravelInfoDirections");
    return new Promise((resolve, reject) => {
      try {
        const optionsDirections: google.maps.DirectionsRequest = {
          origin: coordsOrigin,
          destination: coordsDestination,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          // avoidHighways: true,
          // optimizeWaypoints: true
          // provideRouteAlternatives: true,
          // transitOptions: {
          //   routingPreference: google.maps.TransitRoutePreference.LESS_WALKING
          // }
        };

        this.diretctionsService.route(
          optionsDirections,
          function (response, status) {
            if (status !== "OK") {
              reject(response);
            } else {
              if (
                response.routes &&
                response.routes.length == 1 &&
                response.routes[0].legs
              ) {
                const dataInfo = response.routes[0].legs[0];
                destination.distance = Number(dataInfo.distance.value);
                destination.distanceInfo = dataInfo.distance.text;
                destination.duration = Number(dataInfo.duration.value);
                destination.durationInfo = dataInfo.duration.text;
                resolve(destination);
              } else {
                resolve(null);
              }
            }
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  }

  getTravelInfoDistanceMatrix(
    destination: Destination,
    coordsOrigin,
    coordsDestination
  ) {
    return new Promise((resolve, reject) => {
      let destinations: any = [];

      destinations.push(coordsDestination);
      const options = {
        origins: [coordsOrigin],
        destinations: destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      };

      try {
        this.distanceMatrixService.getDistanceMatrix(
          options,
          function (response, status) {
            if (status !== "OK") {
              reject(response);
            } else {
              if (response.rows && response.rows.length > 0) {
                const element = response.rows[0].elements[0];
                if (element.status === "OK") {
                  destination.distance = Number(element.distance.value);
                  destination.distanceInfo = element.distance.text;
                  destination.duration = Number(element.duration.value);
                  destination.durationInfo = element.duration.text;
                  resolve(destination);
                } else {
                  resolve(null);
                }
              }
            }
          }
        );
      } catch (err) {
        reject(err);
        console.warn(err);
      }
    });
  }

  async getTravelInfo(destination: Destination) {
    // let orig = `${this.data.client.address.street} ${this.data.client.address.number} ${this.data.client.address.neighborhood} ${this.data.client.address.city} ${this.data.client.address.state}`;

    return new Promise<Destination>(async (resolve, reject) => {
      try {
        const coordsOrigin = new google.maps.LatLng(
          this.data.client.address.location.latitude,
          this.data.client.address.location.longitude
        );

        const coordsDestination = new google.maps.LatLng(
          destination.latitude,
          destination.longitude
        );

        let resp = null;

        // await this.getTravelInfoDirections(
        //   destination,
        //   coordsOrigin,
        //   coordsDestination
        // ).then((res: any) => {
        //   resp = res;
        // });

        // if (resp && resp.placeId) {
        //   resolve(resp);
        // } else {
          await this.getTravelInfoDistanceMatrix(
            destination,
            coordsOrigin,
            coordsDestination
          ).then((res: any) => {
            resp = res;
          });
        // }
        await resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  getCoordsFromAddress(
    address: string,
    postalCode?: string,
    place?: string,
    province?: string,
    region?: string,
    country?: string
  ) {
    let compositeAddress = [address];

    if (postalCode) compositeAddress.push(postalCode);
    if (place) compositeAddress.push(place);
    if (province) compositeAddress.push(province);
    if (region) compositeAddress.push(region);
    if (country) compositeAddress.push(country);

    let url = `${this.API_URL}${compositeAddress.join(",")}`;
    return this.http.get(url).toPromise();
  }
}
