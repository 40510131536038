/* eslint-disable no-unused-vars */
import Dictionary from "./interfaces/dictionary";
import { EnumDictionary } from "./interfaces/enumDictionary";

// eslint-disable-next-line no-shadow
enum TariffStatusType {
  Active = 1,
  Inactive = 0,
  Schedule = 2
}

const StatusTypeText: EnumDictionary<TariffStatusType, string> = {
  [TariffStatusType.Active]: 'Ativado',
  [TariffStatusType.Inactive]: 'Desativado',
  [TariffStatusType.Schedule]: 'Agendado'
};

const StatusTypeList: Dictionary[] = [
  { key: 1, value: 'Ativado' },
  { key: 0, value: 'Desativado' },
  { key: 2, value: 'Agendado' }
];

export { TariffStatusType, StatusTypeText, StatusTypeList };
