<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/juma-topo.png', width: 145, height: 52, alt: 'JUMA'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/juma-topo.png', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'">
  <ul class="nav navbar-nav d-md-down-none">
    <h5 *ngIf="!this.data.clientSelect2 || this.data.clientSelect2.length == 0" class="text-muted mt-2">{{this.data.client.name}} - {{this.data.client.businessName}}</h5> 
    <ng-select2 [disabled]="data.subsidiaries.length == 1" *ngIf="data.subsidiaries.length" class="text-left" [(ngModel)]="this.data.subsidiarySelected" [data]="this.data.subsidiaries" [options]="select2OptionsSub"
      [placeholder]="this.data.subsidiarySelected?.name" (valueChanged)="onSubChanged($event)">
    </ng-select2>

    <ng-select2 *ngIf="this.data.clientSelect2 && this.data.clientSelect2.length > 0" class="text-left" [(ngModel)]="this.data.clientSelected" [data]="this.data.clientSelect2" [options]="select2Options"
    [placeholder]="this.data.client?.name" (valueChanged)="onClientChanged($event)">
  </ng-select2>
  </ul>

  <ul class="nav navbar-nav ml-auto">
    <li class="d-none d-md-block">
      <h5 *ngIf="data.balance && data.balance.currentBalance > 0" class="text-muted mt-2 ml-2">Saldo {{(data.balance.currentBalance * 0.01) | currency : 'R$' }}</h5>
    </li>

    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img *ngIf="data.currentUser && data.currentUser.photoURL" src="{{data.currentUser.photoURL}}" class="img-avatar"/>
        <img *ngIf="data.currentUser && !data.currentUser.photoURL" src="assets/img/brand/market.png" class="img-avatar"/>
      </a>
    </li>

  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="data.getNavItems()" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>

  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb></cui-breadcrumb> -->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>

  <app-aside [fixed]="true" [display]="false" [ngClass]="'test'">
    <tabset *ngIf="this.data.client">

      <div class="list-group list-group-accent">
        <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">
          Usuário</div>

          <div class="list-group-item list-group-item-accent-info" aria-labelledby="simple-dropdown">
            <!-- <div class="dropdown-header text-center"><strong>Account</strong></div>
            <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>
            <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>
            <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a>
            <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span class="badge badge-warning">42</span></a> -->
            <!-- <div class="dropdown-header text-center"><strong>Settings</strong></div> -->
            <!-- <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
            <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
            <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
            <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a>
            <div class="divider"></div> -->
            <div class="message">
              <div>
                <small class="text-muted">{{this.data.client.businessName}}</small>
              </div>
              <div>
                <small *ngIf="data.currentUser" class="text-muted mr-3"><i class="icon-envelope-open"></i>  {{this.data.currentUser.email}}</small>
              </div>
              <div>
                <small *ngIf="data.currentUser" class="text-muted"> <i class="icon-screen-smartphone"></i> 
                  {{this.data.currentUser.phoneNumber}}</small>
              </div>
            </div>
            <a class="dropdown-item" href="#"></a>
            <a class="dropdown-item" (click)="sendPassword()"><i class="fa fa-lock"></i> Alteração de senha</a>
            <a class="dropdown-item" href="login"><i class="fa fa-sign-out"></i> Sair</a>
          </div>

          <div *ngIf="this.info && this.info.length > 0" class="list-group-item list-group-item-accent-success" aria-labelledby="simple-dropdown">
            <div class="message">
              <div>
                <small class="text">{{this.info}}</small>
              </div>
            </div>
          </div>

          <div *ngIf="this.warning && this.warning.length > 0" class="list-group-item list-group-item-accent-warning" aria-labelledby="simple-dropdown">
            <div class="message">
              <div>
                <small class="text">{{this.warning}}</small>
              </div>
            </div>
          </div>

        <!-- <div class="list-group-item list-group-item-accent-info">

          <div class="message">
            <div>
              <small class="text-muted">{{this.data.client.businessName}}</small>
            </div>
            <div>
              <small class="text-muted mr-3"><i class="icon-envelope-open"></i>  {{this.data.currentUser.email}}</small>
            </div>
            <div>
              <small class="text-muted"> <i class="icon-screen-smartphone"></i> 
                {{this.data.currentUser.phoneNumber}}</small>
            </div>
          </div>

        </div>
        <div class="list-group-item list-group-item-accent-danger list-group-item-divider">
          <div class="message" >
            <small class="text-muted" (click)="logout()">
              <i class="icon-logout"></i>  SAIR</small>
          </div>
        </div> -->
      </div>
      <!-- </tab> -->

    </tabset>
  </app-aside>

</div>
<app-footer>
  <span>Boaztech &copy;{{year}} | Franquia {{data?.subsidiary?.description}} | v{{data?.app.version}}</span>
</app-footer>