import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  DocumentChangeAction,
  Query,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { firebaseConsts } from "./constants";
import { CategoryDriverList } from "./models/enuns/categoryDriver";
import { ApiService } from "./services/api/api.service";
import { DataService } from "./services/data.service";
import { FirebaseDatabaseService } from "./services/firebase-database.service";
import * as moment from "moment";
import { GeolocationService } from "./services/geolocation.service";
import { parseISO, subHours } from "date-fns";
import * as firebase from "firebase/app";
import * as geofirex from "geofirex";
import { FirestoreStatusDoc } from "./models/enuns/firestoreStatusDoc";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  geo = geofirex.init(firebase);
  driveCategories = [];
  constructor(
    public db: FirebaseDatabaseService,
    private afs: AngularFirestore,
    private api: ApiService,
    public data: DataService,
    public geolocationService: GeolocationService,
  ) {
    this.driveCategories = CategoryDriverList;
    // console.log(this.driveCategories);
  }

  // getOnlineBySubsidiary(subsidiary) {
  //   let ref = this.afs.collection(firebaseConsts.collections.online).ref;
  //   let query: Query;
  //   query = ref.where("subsidiary.id", "==", subsidiary);
  //   return this.db.getCollection(query);
  // }

  // getOnlineNoBusyBySubsidiary(subsidiary) {
  //   let ref = this.afs.collection(firebaseConsts.collections.online).ref;
  //   let query: Query;
  //   query = ref
  //     .where("subsidiary.id", "==", subsidiary)
  //     .where("busy", "==", false);
  //   return this.db.getCollection(query);
  // }

  // getDriversBySubsidiary(subsidiary) {
  //   let ref = this.afs.collection(firebaseConsts.collections.drivers).ref;
  //   let query: Query;
  //   query = ref.where("busy", "==", false);
  //   query = ref.where("subsidiary", "==", subsidiary);
  //   return this.db.getCollection(query);
  // }

  // getDriversByCategory(category) {
  //   let ref = this.afs.collection(firebaseConsts.collections.drivers).ref;
  //   let query: Query;
  //   query = ref.where("category", "==", category);
  //   return this.db.getCollection(query);
  // }

  // getSettings() {
  //   let ref = this.afs.collection("settings").ref;
  //   let query: Query;
  //   query = ref.where("system", "==", "driverapp").where("version", "==", 120);
  //   return this.db.getCollection(query);
  // }

  // deleteSettings() {
  //   this.getSettings().then((res: any) => {
  //     console.log(res);
  //     res.forEach((element) => {
  //       this.afs.collection("settings").doc(element.key).delete();
  //     });
  //   });
  // }

  // getCalls(subsidiary) {
  //   let ref = this.afs.collection("calls").ref;
  //   let query: Query;
  //   query = ref.where("subsidiary", "==", subsidiary);
  //   return this.db.getCollection(query);
  // }

  // saveCalls() {
  //   const sub = "wIz9xk51l5vMcCHOvfBB";
  //   this.getCalls(sub).then(async (res) => {
  //     // writeFileSync('calls.json',res)
  //     // await writeJsonFile('calls.json', res);
  //   });
  // }

  // async getDriversTokens() {
  //   const subsidiary = "cge4p7Qx12NITvzgeYA1";
  //   let onlinelist = [];

  //   await this.getDriversBySubsidiary(subsidiary).then((res: any) => {
  //     let tokens = [
  //       "fze386nD4tY:APA91bGp32aRIwg9pTsYs9UzjDER5-gK3obw-Ej83DDD4H4GIjyxqCvpAycq3Vug87yr1EjG9J-LafAI5i6tORFAPKJvBku4f9WzHAfxeSbeL6CBdusovS2aHQJCG4VdbwdZF0Mr_n2L",
  //     ];
  //     let lokensstring = "";
  //     console.log(res.length);

  //     const novalidtokens = [
  //       "daYLgHt_sf8:APA91bFpB-kuon-s7Fkn0iGRAW2Dy1BS2vLgJJ4j0covKJAI6Z1SCcAsqxTwb3siGFL5QUGTn3iMqtcSJez_g-HJ_ZyyP9J-BY2vLSR1BrtbQmwvUSM2Ay2b0eCuxRyHHAcJaV0dB_8K",
  //       "c4BpwMTuzE8:APA91bHDJNHNr4gZc8dei_bdS4dPGrTRr09LwpmHL0DGCJnwEnzUdv7mKCXs5cBJU1kkSJOxGBMfcNGq-BHekucbbZPqMFcu5Odol6IRHGVQxJAj0qlMPiQ294xpoXNK0z2oJVy8qM3a",
  //       "eGt9KE77Cxs:APA91bE8Tukb2dHPrpZ--DScwRmcGUxs-iEFAZ2tISG4kav7uqKnSs77sJ2UJcd7ccWFrS88nN9IfQrXBTYgd6SCZVRNbw8TXkRkwNKFl_P9wEBvtu-yMhKlB9v349y9xpNtF_qMNfXf",
  //       "dnmmSP3uAPc:APA91bH9JtCgAf70zOZo_52j5ePG4HtcH5BpV-1AuEIp6kx5TSlAhPCwPQbRVgvm0StwJhmXCsk5-REUFCKUU2qcNr2_bq-eZRjPDjYbLWyzaN_YBBhgNYh-nC8QzwzuDp9OXWScH-49",
  //       "fxJUV6ltTpI:APA91bGdsdyDYhuTIOhS3mZ_bYUzoTzuG6HLOKmAzrnASwBNAsO3EmxyaypxuDiYFRdrVv_ho-mb5q-dCXkzIAE3MzXqqCC_1hUvV9ARJvyVKqkqoflCdqq_4ANefyg5Jlt-XRwksdoq",
  //       "d0SXPNK56BA:APA91bGP3xnR55-fhc8MJW-oQwFPgobR4W1Af2kw9mbO807lBO6Coq-Ga1yzOPVQIbHF_k0AwsKBP1jRWLg08yzlV8Jvt_-DbreTJ6zPc2wk-jYdodY5HBV4i88IwfyTY-qlodMnZWq3",
  //       "cbSZH0pVVvk:APA91bGHClwPFml-cL6cUf1ID9IVsyBbvY3-n05eJcp1xN075VLB-miweECeF6VIhqwCwL7vkdECFcJPa2LzjSEqffq3klQS8y3jcH19gh8PM_inWGUjB1bDFuoLVmvy5o1Nyfp8YxYA",
  //       "eyMMuD4HFB8:APA91bH0blspsq4z9Bsu0OCrfZOqqvR6WhoBCOOSNZrmBiNEFQavRkeGqb5j62imYhxe79O_u6tl5lOQ5wlQ4k6TKcUuy4wdRuzfpvdSCmyA_G4YseZqYXN2r0U3egIPKRfDUUmwEWaR",
  //       "esEn4Mam2UE:APA91bEkMTf4_2nI4jQ2tLNO3oPmM9J78Qtn0nY4gDEL2xnbw5a9PkcqaaZGwSArYm600qnIB8-WDw9WxN_4MWNsbGMvkBYASZeB57pEqeV-L25TbVrU_6eL9ZtLTAOghukO5EAFJo2T",
  //     ];

  //     console.log("Quantidade: " + tokens.length);
  //     // console.log(lokensstring);

  //     const body = {
  //       tokens: tokens,
  //       // title: "Entrega!",
  //       // message:
  //       //   "Existem entregas pendentes na sua área. Fique online caso queira aceitar",
  //       data: "newcall",
  //       priority: "high",
  //     };
  //     this.api.sendPush(body).then((resp) => {
  //       console.log(resp);
  //     });
  //   });
  // }

  public watchDriversAvailables(subsidiaryId: string, radius: number, limit: number): Observable<geofirex.GeoQueryDocument[]> {
    let ref = this.afs
      .collection(firebaseConsts.collections.online)
      .ref.where("subsidiary.id", "==", subsidiaryId)
      .where("currentCall.id", "==", null)
      .orderBy('updatedAt', 'desc')
      .limit(limit);
    const geoRef = this.geo.query(ref);
    const latitude = this.data.client.address.location.latitude;
    const longitude = this.data.client.address.location.longitude;
    const center = this.geo.point(latitude, longitude);
    const field = "position";
    const query = geoRef.within(center, radius, field);
    return query;
  }

  getActiveDrivers(subsidiary, category) {
    const startUpdateDate = subHours(new Date(), 1);
    let ref = this.db.afs.collection(firebaseConsts.collections.drivers).ref;
    let query: Query;
    query = ref
      .where("subsidiary", "==", subsidiary)
      .where("category", "==", category)
      .where("status", "==", 3)
      .orderBy("updatedAt")
      .startAt(startUpdateDate);
    return this.db.getCollection(query);
  }

  // public watchActiveDrivers(subsidiary,category): Observable<DocumentChangeAction<any>[]> {
  //   let ref = this.db.afs.collection(firebaseConsts.collections.drivers, (ref) =>
  //     ref.where("subsidiary", "==", subsidiary)
  //     .where("category", "==", category)
  //     .where("available", "==", true)
  //   );
  //   return ref.stateChanges();
  // }

  getDriversNear() {
    return [];
  }

  getCategoryName() {
    if (this.data.defaultCategoryType) {
      return CategoryDriverList.find(
        (r) => r.key === this.data.defaultCategoryType
      ).value;
    } else {
      return "Categoria não selecionada";
    }
  }

  getProfile(driver) {
    const distanceInfo = driver.distance > 0 ? `<p class="text-muted font-size-sm mt-0 mb-0">Distância ${driver.distance} km</p>` : '';
    return `<div class="container mt-4 d-flex justify-content-center">

             <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img src=${driver.driver.photo} alt="foto" class="rounded-circle" width="150">
                    <div class="mt-3">
                      <h4 class="mb-0">${driver.driver.name}</h4>
                      <p class="text-muted font-size-sm mt-0 mb-0">${driver.driver.categoryName}</p>
                      ${distanceInfo}
                      <!--  <button class="btn btn-primary">Follow</button> -->
                      <!-- <button class="btn btn-outline-primary">Message</button> -->
                    </div>
                  </div>
                </div>
              </div>
           </div>`;
    //     return `<div class="container mt-5 d-flex justify-content-center">

    //     <div class="card p-3">

    //         <div class="d-flex align-items-center">

    //             <div class="image">
    //         <img src=${driver.additional.image} class="rounded" width="155" >
    //         </div>

    //         <div class="ml-3 w-100">

    //            <h4 class="mb-0 mt-0">${driver.driver.name}</h4>
    //            <span>${driver.driver.categoryName}</span>

    //            <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">

    //             <div class="d-flex flex-column">

    //                 <span class="articles">Articles</span>
    //                 <span class="number1">38</span>

    //             </div>

    //             <div class="d-flex flex-column">

    //                 <span class="followers">Followers</span>
    //                 <span class="number2">980</span>

    //             </div>

    //             <div class="d-flex flex-column">

    //                 <span class="rating">Rating</span>
    //                 <span class="number3">8.9</span>

    //             </div>

    //            </div>

    //            <div class="button mt-2 d-flex flex-row align-items-center">

    //             <button class="btn btn-sm btn-outline-primary w-100">Chat</button>
    //             <button class="btn btn-sm btn-primary w-100 ml-2">Follow</button>

    //            </div>

    //         </div>

    //         </div>
    //     </div>
    //  </div>`
  }

  // async subscriberToActiveDrivers() {
  //   if (this.data.subscribeActiveDrivers) this.data.subscribeActiveDrivers.unsubscribe();
  //   // this.data.driverList = [];

  //   this.data.subscribeActiveDrivers = this.watchActiveDrivers(this.data.subsidiary.id, this.data.defaultCategoryType)
  //   .subscribe(async documentSnapshots => {
  //     // const drivers = [];
  //     documentSnapshots.forEach(async (documentSnapshot) => {
  //       const data = documentSnapshot.payload.doc.data();
  //       const categoryName = CategoryDriverList.find((r) => r.key === data.category).value;
  //       const driver = {
  //         id: documentSnapshot.payload.doc.id,
  //         text: `${data.name}`,
  //         phone: data.phoneNumber,
  //         category: data.category,
  //         categoryName: categoryName,
  //         online: false,
  //         additional: {
  //           image: data.photoURL,
  //         }
  //       }
  //     });
  //   });
  // }

  async loadActiveDrivers() {
    const drivers: any = await this.getActiveDrivers(
      this.data.subsidiary.id,
      this.data.defaultCategoryType
    );
    this.data.driverList = [];
    
    for (let index = 0; index < drivers.length; index++) {
      const driver = drivers[index];
      const categoryName = CategoryDriverList.find(
        (r) => r.key == driver.category
      ).value;
      this.data.driverList.push({
        id: driver.key,
        text: `${driver.name}`,
        phone: driver.phoneNumber,
        category: driver.category,
        categoryName: categoryName,
        online: false,
        additional: {
          image: driver.photoURL,
        },
      });
    }
    // this.data.driverList
    this.data.driverList.sort(function (a, b) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });

    // sort(function (a, b) {
    //   return a.date - b.date;
    // });
  }

  // TODO 02/12/2021
  // Quando for obter os entregadores online da collection driver remover o indice:
  //online	currentCall.id Crescente subsidiary.id Crescente position.geohash Crescente

  // async getAvailablesDriverNear(subsidiary) {
  //   if (this.data.subscribeDriversAvailable)
  //   this.data.subscribeDriversAvailable.unsubscribe();
  //   this.data.subscribeDriversAvailable = this.watchDriversAvailables(subsidiary, 1).subscribe(
  //     async (documentSnapshots) => {
  //       this.data.nearOnlineDrivers = [];

  //       await documentSnapshots.forEach(async (doc) => {
  //         var obj: any = doc;
  //         const driver = {
  //           driver: {
  //             id: obj.id,
  //             name: obj.driver.name,
  //             phone: obj.driver.phone,
  //             category: obj.driver.category,
  //             categoryName: CategoryDriverList.find(r => r.key === obj.driver.category).value
  //           },
  //           currentCall: obj.currentCall,
  //           location: {
  //             latitude: obj.location.latitude,
  //             longitude: obj.location.longitude,
  //           },
  //           stationary: obj.stationary,
  //           distance: Number(obj.hitMetadata.distance).toFixed(1),
  //           additional: {
  //             image: obj.driver.photo,
  //           },
  //         }
  //         // console.log(driver);
  //         if (driver)
  //         this.dataHandlerService.setDriverOnMap(driver);
  //       })

  //       console.log(this.data.nearOnlineDrivers);

  //     })

  // }

}
