import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MoneyService {

  constructor() { }

  convertToCents(value: any) {
    // let valueConverted: any = value.toString();
    const valueConverted = (parseFloat(value) * 100).toString()
    // if (valueConverted.indexOf('.') > -1) {
    //   valueConverted = Number(valueConverted.replace('.', ''))
    // } else {
    //   valueConverted = Number(valueConverted * 100);
    // }
    return Number(valueConverted);
  }
}
