
export const constantValues = {
    companyType: 'M',
    personType: 'F',
    userType: 'U',
    blockStatus: 'B',
    activeStatus: 'A'
};

export const googleParams = {
    geocodeApikey: 'AIzaSyBws7-KYhbrHa7HENgFeMD94-KhPtRhjrI',
    directionsApikey: 'AIzaSyBws7-KYhbrHa7HENgFeMD94-KhPtRhjrI'
}

export const firebaseConsts = {
    storage: {
        photo: 'photo',
        clients: 'clients'
    },

    collections: {
        user: 'users',
        clients: 'clients',
        users: 'users',
        drivers: 'drivers',
        neighborhoodTariff: 'neighborhood-tariffs',
        calls: 'calls',
        online: 'online',
        cards: 'cards',
        terms: 'terms',
        stockTransaction: 'stock-transaction',
        subsidiaries: 'subsidiaries',
        tariffs: 'tariffs',
        tariffsDynamics: 'tariffsDynamics',
        settings: 'settings',
        destinationsRegistered: 'destinations-registered',
        logs: 'logs',
        news: 'news',
        images: 'images',
        balance: 'balance',
        servicesKeys: 'services-keys',
        tracking: 'tracking'
    },
    docStatus: {
        published: 'P',
        noPublished: 'N',
        locked: 'L'
    },
    fareType: {
        normal: 'normal',
        dynamic: 'dynamic'
    }
}
