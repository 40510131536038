import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ApiClientService {
  constructor(public http: HttpClient) {}

  get(url) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*"
      }),
    };

    return this.http.get(url).toPromise();
  }

  post(url) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*"
      }),
    };

    return this.http.get(url).toPromise();
  }
}
