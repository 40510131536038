import { Injectable } from "@angular/core";
import { firebaseConsts } from "../constants";
import { GoogleLibrary } from "../models/enuns/googleLibrary";
import { FirebaseDatabaseService } from "../services/firebase-database.service";


@Injectable({
  providedIn: 'root'
})
export class KeysHelper {

  constructor(
    public db: FirebaseDatabaseService
  ) { }
  
  randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async getGoogleKeys(library: GoogleLibrary) {
    const keysRef = this.db.afs.collection(firebaseConsts.collections.servicesKeys).ref
      .where('owner', '==', null)
      .where('status', '==', 'A')
      .where('library', 'array-contains' , library);
    try {
      const keysDocs = await keysRef.get();      
      if (!keysDocs.empty) {
        const data = keysDocs.docs[0].data();;
        return data.keys;
      } else {
        return [];
      }
    } catch (err) {
      console.warn(err);
    }
  }

  async getGoogleKey(library: GoogleLibrary) {
    const keysRef = this.db.afs.collection(firebaseConsts.collections.servicesKeys).ref
      .where('owner', '==', null)
      .where('status', '==', 'A')
      .where('library', 'array-contains' , library);
    try {
      const keysDocs = await keysRef.get();      
      if (!keysDocs.empty) {
        const data = keysDocs.docs[0].data();
        const keyOrder = this.randomIntFromInterval(0, data.keys.length-1);
        const key = data.keys[keyOrder];
        return key;
      } else {
        return null;
      }
    } catch (err) {
      console.warn(err);
    }
  }
} 