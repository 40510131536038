import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  redis: any;
  messsageRoom = [];

  constructor(private socket: Socket) {}

  onFetchBusyDrivers(busyDriversChannel: string) {
    return this.socket.fromEvent(busyDriversChannel);
  }

  onFetchFreeDrivers(freeDriversChannel: string) {
    return this.socket.fromEvent(freeDriversChannel);
  }

  onFetchCalls(channel: string) {
    return this.socket.fromEvent(channel);
  }

  onFetchCallsComplete(channel: string) {
    return this.socket.fromEvent(channel);
  }

  onDeletePositions(channel: string) {
    return this.socket.fromEvent(channel);
  }
}
