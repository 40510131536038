import Dictionary from "../interfaces/dictionary";

export enum CallStartOn {
    Undefined = '0',
    NearStore = '1',
    StoreByQrCode = '2'
  }

export const CallStartOnList: Dictionary[] = [
    { key: '0', value: 'Não definido', text: 'Será iniciada em qualquer lugar' },
    { key: '1', value: 'Próximo a loja', text: 'Será iniciada nas proximidades do local de coleta' },
    // { key: '2', value: 'Na loja QRCode', text: 'Será iniciada quando o entregador ler o QRCode na loja após a coleta. Essa opção obriga o entregador a iniciar a entrega no local de coleta.' }
];