import Dictionary from "../interfaces/dictionary";

export enum CallFinishableBy {
  Driver = '0',
  Store = '1',
  DriverNearStore = '2',
  DriverStoreQrCode = '3',
}

export const CallFinishableByList: Dictionary[] = [
  { key: '0', value: 'Entregador', text: 'Será finalizada pelo entregador a qualquer momento e lugar' },
  { key: '1', value: 'Estabelecimento', text: 'Será finalizada pelo operador desse sistema'  },
  { key: '2', value: 'Entregador ao retornar a loja', text: 'Será finalizada pelo entregador nas proximidades da loja'  },
  // { key: '3', value: 'Entregador leitura por QRCode na loja', text: 'Será finalizada pelo entregador por leitura de QRCode. Esta opção obriga o entregador a dirigir-se ao local do QRCode impresso, geralmente é o local onde o entregador deverá devolver maquinda de cartão, troco... Imprima esse QRCode e disponibilize-o próximo a esse local.'  }
];
