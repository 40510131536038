import { CalculationMethods } from "../services/dashboard.service";
import { Address } from "./address";
import { Destination } from "./destination";
import { CallFinishableBy } from "./enuns/callFinishable";
import CategoryDriver from "./enuns/categoryDriver";
import { Settings } from "./interfaces/settings";

export enum StatusCall {
  complete = "complete",
  ongoing = "ongoing",
  accepted = "accepted",
  waiting = "waiting",
  pending = "pending",
  canceled = "canceled",
  imported = "imported",
}

export enum StatusDestination {
  pending = "pending",
  done = "done",
}

export interface Driver {
  id: string;
  name: string;
  category: number;
  phone: string;
  photo: string;
  appVersion?: string | null;
}

export interface Subsidiary {
  id: string;
  key?: string;
  description: string;
  settings: Settings;
  disabledAt?: any | null;
}

export interface Tariff {
  categoryDriver: CategoryDriver;
  calculationMethod: CalculationMethods.kilometerTable;
  description: string;
  value: number;
  distanceValue: number;
}

export interface Origin {
  clientId: string;
  responsible: string;
  imageUrl: string;
  address: any;
  phoneNumber: string;
  host: any | null;
}

export class Call {
  id?: string;
  key?: string;
  franchise: string;
  time: string;
  forwardedDriver: any | null;
  origin: Origin;
  clientKey: string;
  payment: any;
  location: any;
  clientName: string;
  imageUrl: string;
  status: string;
  statusHistory: any[];
  clientAddress: any;
  destinations: Destination[];
  hasDriver: boolean;
  driver: Driver;
  complete: boolean;
  subsidiary: string;
  subsidiaryTemp: any;
  categoryDriver: number;
  categoryDriverName: string;
  order: any;
  stage: number;
  drivers: string[];
  hasBack: boolean;
  notifications: any[];
  date: any;
  createdAt: any;
  tariff: Tariff;
  salesChannel?: any;
  availableDrivers?: any;
  finishableBy: CallFinishableBy;
  callDriverBy: string | null = null;
  endCallPosition: any | null;

  constructor() {
    this.destinations = Array<Destination>();
    this.statusHistory = [];
    this.notifications = [];
    this.drivers = [];
    status = "waiting";
    this.stage = 0;
    this.complete = false;
    this.driver = {
      id: null,
      name: null,
      category: null,
      phone: null,
      photo: null
    }
    const address = new Address();
    this.origin = {
      clientId: '',
      responsible: '',
      imageUrl: '',
      address: address,
      phoneNumber: null,
      host: null
    }
  }

  static prepareData(call: Call) {
    let destinationsArray = [];

    for (let index = 0; index < call.destinations.length; index++) {
      const element = call.destinations[index];
      let item = {
        address: element.address,
        addressObj: element.addressObj,
        comments: element.comments,
        complement: element.complement,
        latitude: element.latitude,
        longitude: element.longitude,
        receiverName: element.receiverName,
        receiverPhone: element.receiverPhone,
        cost: element.cost,
        distance: element.distance,
        distanceInfo: element.distanceInfo,
        duration: element.duration,
        durationInfo: element.durationInfo,
        tariff: element.tariff,
        status: element.status ? element.status : null,
        step: element.step,
        priorSteps: Object.assign({}, element.priorSteps) 
      };
      destinationsArray.push(item);
    }

    let notifications = [];
    for (let index = 0; index < call.notifications.length; index++) {
      const element = call.notifications[index];
      let item = {
        sender: element.sender,
        senderKey: element.senderKey,
        message: element.message,
        read: element.read,
        createdAt: element.createdAt,
      };
      notifications.push(item);
    }

    let drivers = [];
    for (let index = 0; index < call.drivers.length; index++) {
      const element = call.drivers[index];
      drivers.push(element);
    }

    let jsonData = {
      origin: call.origin,
      clientKey: call.clientKey,
      location: call.location,
      driver: call.driver ? call.driver : null,
      clientName: call.clientName,
      imageUrl: call.imageUrl,
      status: call.status,
      statusHistory: call.statusHistory,
      destinations: destinationsArray,
      notifications: notifications,
      clientAddress: call.clientAddress,
      complete: call.complete,
      subsidiary: call.subsidiary,
      subsidiaryTemp: call.subsidiaryTemp,
      hasBack: call.hasBack,
      payment: call.payment,
      device: 1,
      categoryDriver: call.categoryDriver,
      categoryDriverName: call.categoryDriverName,
      availableDrivers: Object.assign({}, call.availableDrivers),
      stage: call.stage,
      drivers: Object.assign([], drivers),
      finishableBy: call.finishableBy,
      callDriverBy: call.callDriverBy,
      forwardedDriver: call.forwardedDriver ? call.forwardedDriver : null,
      endCallPosition: call.endCallPosition,
      ignoreRadius: false
    };
    return jsonData;
  }
}
