import IAddress from "./interfaces/address";

export const brazilStates = [
  { name: "Acre", short: "AC" },
  { name: "Alagoas", short: "AL" },
  { name: "Amapá", short: "AP" },
  { name: "Amazonas", short: "AM" },
  { name: "Bahia", short: "BA" },
  { name: "Ceará", short: "CE" },
  { name: "Distrito Federal", short: "DF" },
  { name: "Espírito Santo", short: "ES" },
  { name: "Goiás", short: "GO" },
  { name: "Maranhão", short: "MA" },
  { name: "Mato Grosso", short: "MT" },
  { name: "Mato Grosso do Sul", short: "MS" },
  { name: "Minas Gerais", short: "MG" },
  { name: "Pará", short: "PA" },
  { name: "Paraíba", short: "PB" },
  { name: "Paraná", short: "PR" },
  { name: "Pernambuco", short: "PE" },
  { name: "Piauí", short: "PI" },
  { name: "Rio de Janeiro", short: "RJ" },
  { name: "Rio Grande do Norte", short: "RN" },
  { name: "Rio Grande do Sul", short: "RS" },
  { name: "Rondônia", short: "RO" },
  { name: "Roraima", short: "RR" },
  { name: "Santa Catarina", short: "SC" },
  { name: "São Paulo", short: "SP" },
  { name: "Sergipe", short: "SE" },
  { name: "Tocantins", short: "TO" }
];

export class Address implements IAddress {
  placeId?: string;
  zipCode?: string;
  street: string;
  neighborhood: string;
  number: string;
  complement: string | null;
  city: string;
  state: string;
  ibge: string;
  location: firebase.firestore.GeoPoint | null;
  locationUpdatedAt: any | null;
  constructor() {
    this.zipCode = "";
  }

  toString(): string {
    return `${this.street}, ${this.number}, ${this.neighborhood}, ${this.city}, ${this.zipCode}`;
  }
}
