import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringsService {

  constructor() { }

  public capitalize(text) {
    if (typeof text !== 'string') return ''
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  public capitalizeFrase(text: string) {

    let fullText: string = '';
    let parts = text.split(' ');
    parts.forEach(part => {
      if (part.length > 2 && fullText.length == 0)
        fullText = this.capitalize(part);
      else if (part.length > 2)
        fullText = fullText + ' ' + this.capitalize(part);
      else
        fullText = fullText + ' ' + part.toLowerCase();
    });
    return fullText;

  }

  public static removeAccent(str) {
    const has = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    const hasno = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let newStr = "";
    for (let i = 0; i < str.length; i++) {
      let swap = false;
      for (let a = 0; a < has.length; a++) {
        if (str.substr(i, 1) == has.substr(a, 1)) {
          newStr += hasno.substr(a, 1);
          swap = true;
          break;
        }
      }
      if (swap == false) {
        newStr += str.substr(i, 1);
      }
    }
    return newStr;
  }

  replaceAll(str, find, replace) {
    if (str) return str.replace(new RegExp(find, "g"), replace);
    else return '';
  }
  
}
