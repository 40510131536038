import { DataService } from 'src/app/services/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { firebaseConsts } from '../constants';
import { DashboardService } from './dashboard.service';
import { FirebaseDatabaseService } from './firebase-database.service';
import * as firebase from "firebase/app";
import * as geofirex from "geofirex";
import { differenceInHours, differenceInMinutes, parseISO } from 'date-fns';
import { AutoCompleteHelper } from './autoCompleteHelper';
import { StringsService } from './strings.service';
import Coordinate from 'local-packages/ngx-autocom-place/src/lib/model/coordinate';
import AutoCompleteResponse from '../models/interfaces/autoCompleteResponse';
import _ from 'lodash';
import { SeekType } from '../models/enuns/seekType';
import { SeekAddress } from '../models/interfaces/seekAddress';
import * as moment from 'moment';
import { PlaceRequest } from '../models/interfaces/placeRequest';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  radius = 100; // 1000km

  geo = geofirex.init(firebase);
  gereral = 0;

  constructor(
    public http: HttpClient,
    public dashboardService: DashboardService,
    public db: FirebaseDatabaseService,
    public data: DataService
  ) { }

  getAddress(cep: string) {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).toPromise();
  }

  googleGeocode(placeid?, address?) {
    if (placeid) {
      return this.googleGeocodeByPlaceId(placeid);
    } else {
      return this.googleGeocodeByAddress(address);
    }
  }

  getCoordsFromDb(placeid) {
    let ref = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref;
    let query: Query;
    query = ref
      .where("placeId", "==", placeid)
      .limit(1)
    return this.db.getCollection(query);
  }

  // getDestinations() {
  //   // const startUpdateDate = parseISO(
  //   //   moment().subtract(30, "hours").toISOString()
  //   // );

  //   let ref = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref;
  //   let query: Query;
  //   query = ref
  //     .where('clientId', '==', 'YR5WbZIShOgwTJawJSKMF2vXEEh2') //Lugs Tres Lagoas
  //     // .orderBy('updatedAt')
  //     // .endBefore(startUpdateDate)
  //     .limit(10000)
  //   return this.db.getCollection(query);
  // }

  async sendPlace(body) {
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJyZW1vdGVTeXN0ZW0iLCJpYXQiOjE2NTcwNDM3NzYsImV4cCI6MTY1NzA1MDk3Nn0.PddSrQN-Grj34d9XMRI2jNXAO3o66vE0gcPA0ZOWZAg";
    let headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + token)
      .append('Content-Type', 'application/json')
      // .append('Access-Control-Allow-Origin', '*')
    const url = `https://boaztech-places-autocomplete.herokuapp.com/places`;
    return this.http.post(url, body, { headers: headers }).toPromise();
  }

  // updateDestinationsRegisteredLocation() {
  //   this.getDestinations()
  //     .then(async (res: any) => {
  //       let count = 0;

  //       for (let index = 0; index < res.length; index++) {
  //         const element: any = res[index];
  //         const date = element.updatedAt ? new Date(element.updatedAt.seconds * 1000) : new Date(element.createdAt.seconds * 1000);
  //         const key = element.key;
  //         count++;
  //       }

  //       this.gereral = this.gereral + 1;
  //       // // console.log('--'+this.gereral+'--');
  //       // if (this.gereral < 6) {
  //       //   this.updateDestinationsRegisteredLocation();
  //       // }

  //     })
  // }

  updateDoc(id: string) {
    let doc = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref.doc(id);
    return doc.update({ updatedAt: this.db.getNowTimestamp() });
  }

  setDestinationsRegisteredLocation(id: string, geoPoint: any) {
    let doc = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref.doc(id);
    return doc.update({ location: geoPoint, updatedAt: this.db.getNowTimestamp(), createdAt: this.db.getNowTimestamp() });
  }

  // updateDoc(id: string, indexedAddress: any, indexedNeighborhood: any, toDelete: boolean) {
  //   let doc = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref.doc(id);
  //   return doc.update({ toDelete: toDelete, indexedAddress: indexedAddress, indexedNeighborhood: indexedNeighborhood, updatedAt: this.db.getNowTimestamp() });
  // }

  deleteDestinationRegisteredLocation(id: string) {
    return this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref.doc(id).delete();
  }

  getAddressByReverseGeocoding(lat, lng) {
    let url = `${this.dashboardService.API_GEOCODE_URL}&latlng=${lat},${lng}`;
    return this.http.get(url).toPromise();
  }

  async extractAddressComponent(lat, lng) {

    const resp: any = await this.getAddressByReverseGeocoding(lat, lng);
    const addressesComponents: any = resp?.results.filter(row => {
      return row.types.includes('premise') || row.types.includes('street_address')
    });
    const addressComponent = addressesComponents[0];
    // let street = '';
    // let number = '';
    // let neighborhood = '';
    // let city = '';
    // for (let index = 0; index < addressComponent.length; index++) {
    //   const element = addressComponent[index];
    //   if (element.types.includes('street_number')) number = element.long_name;
    //   if (element.types.includes('route')) street = element.long_name;
    //   if (element.types.includes('sublocality') || element.types.includes('sublocality_level_1')) neighborhood = element.long_name;
    //   if (element.types.includes('administrative_area_level_2')) city = element.long_name;
    // }
    // const address = `${street} ${number} ${neighborhood} ${city}`;
    // return StringsService.removeAccent(address.replace('  ', ''));
    return addressComponent;
  }

  async extractAddress(lat, lng) {
    const resp: any = await this.getAddressByReverseGeocoding(lat, lng);
    const addressesComponents: any = resp?.results.filter(row => {
      return row.types.includes('premise') || row.types.includes('street_address')
    });
    const addressComponent = addressesComponents[0];
    let street = '';
    let number = '';
    let neighborhood = '';
    let city = '';
    for (let index = 0; index < addressComponent.length; index++) {
      const element = addressComponent[index];
      if (element.types.includes('street_number')) number = element.long_name;
      if (element.types.includes('route')) street = element.long_name;
      if (element.types.includes('sublocality') || element.types.includes('sublocality_level_1')) neighborhood = element.long_name;
      if (element.types.includes('administrative_area_level_2')) city = element.long_name;
    }
    const address = `${street} ${number} ${neighborhood} ${city}`;
    return StringsService.removeAccent(address.replace('  ', ''));
  }

  googleGeocodeByPlaceId(placeid: string) {
    let url = `${this.dashboardService.API_GEOCODE_URL}&place_id=${placeid}`;
    return this.http.get(url).toPromise();
  }

  googleGeocodeByAddress(address: string) {
    let url = `${this.dashboardService.API_GEOCODE_URL}&address=${address}`;
    return this.http.get(url).toPromise();
  }

  getDirections(address: string) {
    let url = `${this.dashboardService.API_DIRECTIONS_URL}&address=${address}`;
    return this.http.get(url).toPromise();
  }

  public extractInfo(list, type, componentsLength = 0) {
    if (list.length == 0)
      return '';

    let locality = list.filter((info) => {
      const index = info.types.indexOf(type);

      return componentsLength == 0
        ? index > -1
        : index > -1 && info.address_components.length == 3;
    });
    return locality;
  }

  queryAddressBuilderByClient(param, coordinate: Coordinate) {
    const center = this.geo.point(coordinate.latitude, coordinate.longitude);
    let ref = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref
      .where('subsidiaryId', '==', this.data.subsidiary.id)
      .where('clientId', '==', this.data.client.id)
      .where('indexedAddress', 'array-contains', param)
    const geoRef = this.geo.query(ref);
    const queryGeo = geoRef.within(center, this.radius, 'location');
    return queryGeo;
  }

  queryAddressBuilderBySubsidiary(param, coordinate: Coordinate) {
    const center = this.geo.point(coordinate.latitude, coordinate.longitude);
    let ref = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref
      .where('subsidiaryId', '==', this.data.subsidiary.id)
      .where('indexedAddress', 'array-contains', param)
    const geoRef = this.geo.query(ref);
    const queryGeo = geoRef.within(center, this.radius, 'location');
    return queryGeo;
  }

  queryAddressBuilderByNeighborhood(param, coordinate: Coordinate) {
    const center = this.geo.point(coordinate.latitude, coordinate.longitude);
    let ref = this.db.afs.collection(firebaseConsts.collections.destinationsRegistered).ref
      .where('subsidiaryId', '==', this.data.subsidiary.id)
      .where('address.number', '==', 'S/N')
      .where('address.street', '==', 'Rua não informada')
      .where('indexedNeighborhood', 'array-contains', param)
    const geoRef = this.geo.query(ref);
    const queryGeo = geoRef.within(center, this.radius, 'location');
    return queryGeo;
  }

  async getAddressList(param, coordinate) {
    return new Promise<SeekAddress>((resolve, reject) => {
      const result: SeekAddress = { list: [], seekType: SeekType.Address };
      try {
        const queryAddressByNeighborhood = this.queryAddressBuilderByNeighborhood(param, coordinate);
        queryAddressByNeighborhood.subscribe(async docs => {
          if (docs.length) {
            console.log('By neighborhood');
            result.seekType = SeekType.Neighborhood;
            result.list = docs;
            resolve(result);
            return;
          }
        });
        const queryAddressByClient = this.queryAddressBuilderByClient(param, coordinate);
        queryAddressByClient.subscribe(async docs => {
          if (docs.length) {
            console.log('By cliente');
            result.list = docs
            resolve(result);
          } else {
            const queryAddressBySubsidiary = this.queryAddressBuilderByClient(param, coordinate);
            queryAddressBySubsidiary.subscribe(docs => {
              console.log('By subsidiary');
              result.list = docs
              resolve(result);
            })
          }
        })
      } catch (err) {
        reject(err);
      }
    });
  }

  filterList(params: string[], addresses: SeekAddress) {
    const result: SeekAddress = {
      list: [],
      seekType: addresses.seekType
    };
    if (!params || params.length < 2) {
      return addresses;
    }
    params.shift();//get from second param forward
    params.forEach(param => {
      const newList = [];
      // debugger
      for (let index = 0; index < addresses.list.length; index++) {
        const element = addresses.list[index];
        const includesParam = result.seekType == SeekType.Neighborhood ?
          element.indexedNeighborhood.includes(param) :
          element.indexedAddress.includes(param)
        if (includesParam) {
          const lst = _.filter(newList, function (o) {
            return o.placeId == element.placeId;
          });
          if (lst.length == 0) newList.push(element);
        }
      }
      addresses.list = newList;
      result.list = newList;
    });
    return result;
  }

  async autoCompleteMicroservice(input: string, coordinate: Coordinate) {
    input = StringsService.removeAccent(input).toLocaleLowerCase().replace(/-/g, '');
    input = input.replace(/,/g, '');

  }

  async autoComplete(input: string, coordinate: Coordinate) {
    input = StringsService.removeAccent(input).toLocaleLowerCase().replace(/-/g, '');
    input = input.replace(/,/g, '');
    // console.log(input);
    
    const arrayInput = input.split(/(\s+)/).filter(function (e) {
      return e.trim().length > 0;
    });

    const arrayInputMax10Params = arrayInput && arrayInput.length > 10 ? arrayInput.slice(0, 9) : arrayInput;
    if (!arrayInputMax10Params) throw new Error(`Input from auto complete '${input}' invalid`);

    for (let index = 0; index < arrayInputMax10Params.length; index++) {
      const element = arrayInputMax10Params[index];
      if (element.includes('.') || element.includes('º')) {
        arrayInputMax10Params.splice(index, 1);
      }

      if (element.length == 1) {
        const char = Number(element);
        if (isNaN(char)) arrayInputMax10Params.splice(index, 1);
      }

      if (element.length == 2) {
        if ((element == 'de' || element == 'da') && arrayInputMax10Params.length > 2) {
          arrayInputMax10Params.splice(index, 1);
        }
      }
    }

    if (arrayInputMax10Params[0].includes('rua') || arrayInputMax10Params[0].includes('av')) {
      arrayInputMax10Params.shift();
    }

    if (arrayInputMax10Params.length && (arrayInputMax10Params[0].includes('.') || arrayInputMax10Params[0].length < 3)) {
      arrayInputMax10Params.shift();
    }

    // console.log(arrayInputMax10Params);

    if (arrayInputMax10Params.length == 0) return [];
    const param = arrayInputMax10Params.length ? arrayInputMax10Params[0] : '';

    const allAddresses = await this.getAddressList(param, coordinate);
    const addresses = this.filterList(arrayInputMax10Params, allAddresses);

    const result: AutoCompleteResponse[] = [];
    for (let index = 0; index < addresses.list.length; index++) {
      const doc = addresses.list[index];
      const title = addresses.seekType === SeekType.Address ? `${doc.address.street}, ${doc.address.number}` : `${doc.address.neighborhood}`;
      const endSubtitle = `${doc.address.neighborhood}, ${doc.address.city} - ${doc.address.state}`;
      let subtitle = '';
      if (addresses.seekType === SeekType.Address) {
        subtitle = (doc.address && doc.address.street.length > 0 && doc.address.number.length > 0) ?
          `${title} - ${endSubtitle}` : doc.endAddress;
      } else {
        subtitle = endSubtitle;
      }

      const autoCompleteResponse: AutoCompleteResponse = {
        id: doc.placeId,
        title: (doc.address && doc.address.street.length > 0 && doc.address.number.length > 0) ?
          title : doc.endAddress,
        subtitle: subtitle,
        city: doc.address ? doc.address.city : '',
        state: doc.address ? doc.address.state : '',
        street: doc.address ? doc.address.street : '',
        streetNumber: doc.address ? doc.address.number : '',
        neighborhood: doc.address ? doc.address.neighborhood : '',
        postalCode: '',
        location: {
          latitude: doc.address ? doc.address.location.lat : doc.latitude,
          longitude: doc.address ? doc.address.location.lng : doc.longitude
        },
        placeType: ['address']
      };
      result.push(autoCompleteResponse);
    }
    return result;
  }
}
