import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  NgZone,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { AlertConfig } from "ngx-bootstrap/alert";
import { NgxLoadingComponent, ngxLoadingAnimationTypes } from "ngx-loading";

import { FirebaseDatabaseService } from "../../services/firebase-database.service";
import { DataService } from "../../services/data.service";
import { AuthService } from "../../services/auth.service";
import { firebaseConsts } from "src/app/constants";
import { environment } from "src/environments/environment";
import { Address } from "src/app/models/address";
import { SubsidiaryService } from "src/app/services/subsidiary.service";
import { DataHandlerService } from "src/app/services/data-handler.service";

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: "success" });
}

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }],
})
export class LoginComponent implements OnInit {
  year = new Date(Date.now()).getFullYear();
  @ViewChild("inputemail") inputemail: ElementRef;
  @ViewChild("ngxLoading") ngxLoadingComponent: NgxLoadingComponent;
  @ViewChild("customLoadingTemplate") customLoadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading = false;
  capsOn;

  email: string = "";
  password: string = "";
  error = "";
  warn = "";
  info = "";
  environmentName = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private db: FirebaseDatabaseService,
    private dataService: DataService,
    private ngZone: NgZone,
    public data: DataService,
    private dataHandlerService: DataHandlerService
  ) {
    // if (this.data.oauthService)
    this.authService.logoutUser(false);
    // console.log(this.authService.currentUser);
    if (data.subscriptionCheckIfood)
      data.subscriptionCheckIfood.unsubscribe();

    this.environmentName = environment.name;
  }

  private clear() {
    this.warn = "";
    this.error = "";
  }

  ngOnInit() {
    console.info(this.environmentName);
    setTimeout(async () => {
      this.inputemail.nativeElement.focus();
    }, 100);
  }

  async doLogin() {
    this.clear();

    // this.email = 'client@example.com';
    // this.password = 'juma$123';


    if (this.email.length == 0 || this.password.length == 0) {
      this.warn = "Informe o email e senha!";
      return null;
    }

    this.loading = true;

    this.authService
      .loginUser(this.email, this.password)
      .then(async (res) => {
        let userAuth = await res.user;
        const userType = await this.db.getDataById(firebaseConsts.collections.users, userAuth.uid);

        await this.db
          .getDataById(firebaseConsts.collections.clients, userAuth.uid)
          .then(async (res) => {            
            if (res && !userType?.claims) {
              this.dataService.client = res;
              if (this.dataService.client.address)
                this.router.navigate(["dashboard"]);
              else {
                this.data.client.address = new Address();
                this.data.registerPending = true;
                this.data.warning = this.data.textCompleteRegister;
                // this.router.navigate(["company"]);
              }
              this.loading = false;
            } else {
              // const userType = await this.db.getDataById(firebaseConsts.collections.users, userAuth.uid);
              if (!userType?.claims) { 
                this.error =
                "Conta comerciante não encontrada. O email não esta vinculado à uma conta de comerciante.";
              } else {
                await this.dataHandlerService.prepareToAdminManager(userType);
              }
              this.loading = false;

              // const collection = "users";
              // this.firebaseDatabaseService
              //   .getDataById(collection, user.uid)
              //   .then((res) => {
              //     if (res) {
              //       if (res.type === 'A') {
              //         this.dataService.franchisee = new Franchisee();
              //         this.dataService.franchisee.showName = 'Usuário Administrador';
              //         this.dataService.user = res;
              //         this.router.navigate(["master-dashboard"]);
              //       } else {
              //         this.error = "Sua conta não esta vinculada a um fornecedor cadastrado.";
              //       }
              //     }
              //   });
            }
          })
          .catch((err) => {
            this.loading = false;
            console.warn(err);
          });
      })
      .catch((err) => {
        this.loading = false;
        if (err.code) {
          switch (err.code) {
            case "auth/wrong-password":
              this.error = "Usuário ou senha inválido";
              break;
            case "auth/user-not-found":
              this.error = "Email não cadastrado";
              break;
            case "auth/user-disabled":
              this.error = "Conta de usuário bloqueada";
              break;
            case "auth/invalid-email":
              this.error = "O endereço de email com formato inválido";
          }
        }
      });
  }

  callRegister() {
    this.ngZone.run(() => {
      this.router.navigate(["registerclient"]);
    });
  }

  public async getUserData(id: string): Promise<any> {
    let collection = "users";
    return this.db.getDataById(collection, id);
  }

  resetPassword() {
    if (this.email.length == 0) {
      this.warn = "Informe o email cadastrado";
      return null;
    }

    this.authService
      .resetPassword(this.email)
      .then(res => {
        this.info = 'Enviamos um email para '+this.email+' com o "link" para redefinição da senha.';
        this.warn = '';
        console.log(res);
      })
      .catch((err) => {
        this.info = '';
        this.warn = 'Email inexistente ou bloqueado';
        console.warn(err)
      });
  }
}
