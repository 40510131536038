import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/firestore';
import { firebaseConsts } from '../constants';
import { DataService } from './data.service';
import { FirebaseDatabaseService } from './firebase-database.service';

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryService {

  constructor(
    private db: FirebaseDatabaseService,
    private data: DataService
    ) { }

  getClients(subsidiaryId: string | null) {
    let ref = this.db.afs.collection(firebaseConsts.collections.clients).ref;
    let query: Query;
    if (subsidiaryId) {
      console.log('Get client by sub');
      query = ref.where("subsidiary", "==", subsidiaryId).where("disabledAt", "==", null).orderBy('name');
      return this.db.getCollection(query);
    }
    return []
    //else {
      //console.log('Get all clients');
      //query = ref.where("disabledAt", "==", null).orderBy('name');
    //} 
    
  }



}
