import { Injectable } from "@angular/core";
import { differenceInMinutes } from 'date-fns'

@Injectable({
  providedIn: "root",
})
export class FirebaseTokenService {
  private value: string;
  createdAt: Date;

  constructor() {
  }

  age() {
    const now = new Date(Date.now());
    const diff = differenceInMinutes(now, this.createdAt);
    return diff;
  }

  isValid() {
    try {
      const now = new Date(Date.now());
      const diff = differenceInMinutes(now, this.createdAt);
      return diff < 60;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  async renewToken(currentUser: any) {
    this.value = await currentUser.getIdToken(true);
    this.createdAt = new Date(Date.now());
    return this.value;
  }

  async getToken(currentUser: any) {
    if (this.isValid()) {
      return this.value;
    } else {
      return await this.renewToken(currentUser);
    }
  }
}
